<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createHandler"
          icon-pack="feather"
          icon="icon-edit"
          >Add Applicable Area</vs-button
        >
      </div>
    </div>
    <ApplicableAreaList
      :docs="docs"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getList"
      @editSelected="editSelected"
      @deleteSelected="deleteSelected"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ApplicableAreaList from "../../components/applicable-area/List";

export default {
  components: {
    ApplicableAreaList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      popUpDelete: false,
      patients: [],
      noDataText: "Loading...",
      docs: []
    };
  },
  methods: {
    ...mapActions("product", [
      "fetchProductApplicableAreas",
      "deleteApplicableAreaServer"
    ]),
    async getList(dataTableParams) {
      let self = this;
      this.fetchProductApplicableAreas(dataTableParams).then(res => {
        self.docs = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.currentPage = res.data.data.pagination.page;
        self.noDataText = "No Data Available";
      });
    },
    createHandler() {
      this.$router.push({ name: "AddApplicableArea" });
    },
    editSelected(id) {
      this.$router.push({
        name: "SuperAdminEditApplicableArea",
        params: { id: id }
      });
    },
    async deleteSelected(data) {
      let self = this;
      await this.deleteApplicableAreaServer(data.id)
        .then(res => {
          self.popUpDelete = false;
          self.getList(data.dataTableParams);
          this.$vs.notify({
            title: "Applicable Area",
            text: "Applicable Area deleted successfully.",
            color: "success"
          });
        })
        .catch(err => {
          self.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger"
          });
        });
    }
  }
};
</script>
